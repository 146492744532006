<template>
    <div class="content">
        <div class="news-banner">
            <img src="../assets/news-banner.png" width="100%" />
        </div>
        <div class="pd30">
            <div class="tc ft30 l16 mb28">{{tools.title}}</div>
            <div class="flex-end bl9 ft24 van-hairline--bottom pb30 mb40">
                <!--<span class="ml20">所属：{{newsInfo.category == 1 ? '企业新闻' : newsInfo.category == 2 ? '行业动态' : '企业公告'}}</span>-->
                <!--<span class="ml20">浏览量：3500</span>-->
                <span class="ml20">{{tools.updateTime}}</span>
            </div>
            <div class="content tj bl6 mb40 van-hairline--bottom pb24">
                <div class="l18 tj" v-html="tools.content"></div>
            </div>
            <!--<div class="ft24 bl6 line1" @click="handlePrev(newsInfo.preId)">上一篇：{{newsInfo.preTitle ? newsInfo.preTitle : '没有了'}}</div>-->
            <!--<div class="pt16 ft24 line1 bl6" @click="handleNext(newsInfo.nextId)">下一篇：{{newsInfo.nextTitle ? newsInfo.nextTitle : '没有了'}}</div>-->
            <!--<div class="flex-end pt40 van-hairline&#45;&#45;bottom pb40">-->
            <!--<div class="ft24 bl6">分享到：</div>-->
            <!--<img src="../assets/wechat-img.png" style="width: 0.6rem;" class="ml16" />-->
            <!--<img src="../assets/webo-img.png" style="width: 0.6rem;" class="ml16" />-->
            <!--<img src="../assets/qqz-img.png" style="width: 0.6rem;" class="ml16" />-->
            <!--<img src="../assets/douban-img.png" style="width: 0.6rem;" class="ml16" />-->
            <!--<img src="../assets/tieba-img.png" style="width: 0.6rem;" class="ml16" />-->
            <!--</div>-->
            <!--<div class="flex-between pt40 pb30 van-hairline&#45;&#45;bottom">-->
                <!--<span class="ft30">推荐阅读</span>-->
                <!--<span class="ft24 bl6 flex" @click="$router.push('/news')">-->
					<!--查看更多-->
					<!--<van-icon name="arrow"></van-icon>-->
				<!--</span>-->
            <!--</div>-->
            <!--<div class="pt28 ft24 flex-between line1 bl6" v-for="(item,index) in newsList" :key="index">-->
                <!--<div class="line1 pr20">-->
                    <!--{{item.title}}-->
                <!--</div>-->
                <!--<div class="ft24 bl9">{{item.updateTime}}</div>-->
            <!--</div>-->
        </div>
    </div>
</template>

<script>
  import { toolDetails} from '@/api/index.js'
  export default{
    data(){
      return{
        tools:{
          id: '',
          title: '', // 新闻标题
          shortDesc: '', // 新闻摘要
          content:'', // 新闻内容
          updateTime: '', // 发布时间
        },
      }
    },
    created(){
      this.getToolDetail(this.$route.query.id)
    },
    methods:{

      getToolDetail(id){
        toolDetails(id).then(res => {
          if(res.code === 200){
            this.tools = {
              id: res.data.id,
              title: res.data.title, // 新闻标题
              shortDesc: res.data.shortDesc, // 新闻摘要
              content:res.data.content.replace(/\<img/gi, '<img style="max-width:100%;height:auto"'), // 新闻内容
              updateTime: res.data.updateTime, // 发布时间
            }
          }
        })
      },
    }
  }
</script>

<style scoped="scoped">
    .news-nav .item{
        width: 100%;
        border-radius: 100px;
        border: 1px solid #bfbfbf;
        padding: .2rem 0;
        margin-bottom: .2rem;
        color: #666666;
    }
    .news-nav .item.nav-select{
        border-color: #B5232E;
        background-color: #B5232E;
        color: #FFFFFF;
    }
    .news-list .news-list-img{
        overflow: hidden;
        border-radius: .2rem;
        width: 2.13rem;
        height: 1.51rem;
    }
    .news-list .ml20{
        width: 66%;
    }
</style>
